:root {
  --md-source: #fbc02d;
  --cc-a11y-contrast-purple1: #3d0f4e;
  --cc-a11y-contrast-purple2: #4b0a42;
  --cc-a11y-contrast-purple3: #5c2e98;
  --cc-a11y-contrast-purple4: #652d65;
  --cc-a11y-contrast-purple5: #6724c1;
  --cc-a11y-contrast-purple6: #753372;
  --cc-a11y-contrast-purple7: #78038b;
  --cc-a11y-contrast-purple8: #800175;
  --cc-a11y-contrast-purple9: #842f80;
  --cc-a11y-contrast-purple10: #8f02a3;
  --cc-a11y-contrast-green1: #0a3f14;
  --cc-a11y-contrast-green2: #0e2e3a;
  --cc-a11y-contrast-green3: #084125;
  --cc-a11y-contrast-green4: #043e00;
  --cc-a11y-contrast-green5: #333e1b;
  --cc-a11y-contrast-green6: #343c04;
  --cc-a11y-contrast-green7: #363938;
  --cc-a11y-contrast-green8: #173510;
  --cc-a11y-contrast-red1: #4e2d32;
  --cc-a11y-contrast-red2: #90052f;
  --cc-a11y-contrast-blue1: #0047bc;
  --cc-a11y-contrast-blue2: #011c4f;
  --cc-a11y-contrast-blue3: #02385a;
  --cc-a11y-contrast-blue4: #031084;
  --cc-a11y-contrast-blue5: #042c5b;
  --cc-a11y-contrast-blue6: #0714d2;
  --cc-a11y-contrast-blue7: #082960;
  --cc-a11y-contrast-blue8: #20089f;
  --cc-a11y-contrast-blue9: #2435bd;
  --cc-a11y-contrast-blue10: #2f27ea;
  --cc-a11y-contrast-blue11: #2f108e;
  --cc-a11y-contrast-blue12: #2c434b;
  --cc-a11y-contrast-blue13: #3904f7;
  --cc-a11y-contrast-blue14: #394c82;
  --cc-a11y-contrast-blue15: #5324aa;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #261a00;
  --md-ref-palette-primary20: #402d00;
  --md-ref-palette-primary25: #4d3800;
  --md-ref-palette-primary30: #5c4300;
  --md-ref-palette-primary35: #6a4e00;
  --md-ref-palette-primary40: #795900;
  --md-ref-palette-primary50: #987100;
  --md-ref-palette-primary60: #b88900;
  --md-ref-palette-primary70: #d9a200;
  --md-ref-palette-primary80: #f8bd2a;
  --md-ref-palette-primary90: #ffdfa0;
  --md-ref-palette-primary95: #ffefd5;
  --md-ref-palette-primary98: #fff8f2;
  --md-ref-palette-primary99: #fffbff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #001d36;
  --md-ref-palette-secondary20: #003258;
  --md-ref-palette-secondary25: #003d6a;
  --md-ref-palette-secondary30: #00497d;
  --md-ref-palette-secondary35: #005590;
  --md-ref-palette-secondary40: #0061a4;
  --md-ref-palette-secondary50: #007acd;
  --md-ref-palette-secondary60: #2d95ee;
  --md-ref-palette-secondary70: #64afff;
  --md-ref-palette-secondary80: #9ecaff;
  --md-ref-palette-secondary90: #d1e4ff;
  --md-ref-palette-secondary95: #eaf1ff;
  --md-ref-palette-secondary98: #f8f9ff;
  --md-ref-palette-secondary99: #fdfcff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #052100;
  --md-ref-palette-tertiary20: #0d3900;
  --md-ref-palette-tertiary25: #114500;
  --md-ref-palette-tertiary30: #1d5109;
  --md-ref-palette-tertiary35: #295e16;
  --md-ref-palette-tertiary40: #356a21;
  --md-ref-palette-tertiary50: #4e8438;
  --md-ref-palette-tertiary60: #669f4f;
  --md-ref-palette-tertiary70: #80ba67;
  --md-ref-palette-tertiary80: #9ad67f;
  --md-ref-palette-tertiary90: #b6f399;
  --md-ref-palette-tertiary95: #ccffb2;
  --md-ref-palette-tertiary98: #edffdf;
  --md-ref-palette-tertiary99: #f7ffed;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #1e1b16;
  --md-ref-palette-neutral20: #34302a;
  --md-ref-palette-neutral25: #3f3b35;
  --md-ref-palette-neutral30: #4a4640;
  --md-ref-palette-neutral35: #56524b;
  --md-ref-palette-neutral40: #625e57;
  --md-ref-palette-neutral50: #7c766f;
  --md-ref-palette-neutral60: #969088;
  --md-ref-palette-neutral70: #b1aaa2;
  --md-ref-palette-neutral80: #cdc5bd;
  --md-ref-palette-neutral90: #e9e1d8;
  --md-ref-palette-neutral95: #f8efe7;
  --md-ref-palette-neutral98: #fff8f2;
  --md-ref-palette-neutral99: #fffbff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #201b10;
  --md-ref-palette-neutral-variant20: #363024;
  --md-ref-palette-neutral-variant25: #413b2e;
  --md-ref-palette-neutral-variant30: #4d4639;
  --md-ref-palette-neutral-variant35: #595144;
  --md-ref-palette-neutral-variant40: #665d4f;
  --md-ref-palette-neutral-variant50: #7f7667;
  --md-ref-palette-neutral-variant60: #998f80;
  --md-ref-palette-neutral-variant70: #b4aa99;
  --md-ref-palette-neutral-variant80: #d0c5b4;
  --md-ref-palette-neutral-variant90: #ede1cf;
  --md-ref-palette-neutral-variant95: #fcefdd;
  --md-ref-palette-neutral-variant98: #fff8f2;
  --md-ref-palette-neutral-variant99: #fffbff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #795900;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #ffdfa0;
  --md-sys-color-on-primary-container-light: #261a00;
  --md-sys-color-secondary-light: #0061a4;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #d1e4ff;
  --md-sys-color-on-secondary-container-light: #001d36;
  --md-sys-color-tertiary-light: #356a21;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #b6f399;
  --md-sys-color-on-tertiary-container-light: #052100;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #ffffff;
  --md-sys-color-on-background-light: #1e1b16;
  --md-sys-color-surface-light: #f6f6f1;
  --md-sys-color-on-surface-light: #1e1b16;
  --md-sys-color-surface-variant-light: #ede1cf;
  --md-sys-color-on-surface-variant-light: #4d4639;
  --md-sys-color-outline-light: #7f7667;
  --md-sys-color-inverse-on-surface-light: #f8efe7;
  --md-sys-color-inverse-surface-light: #34302a;
  --md-sys-color-inverse-primary-light: #f8bd2a;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #795900;
  --md-sys-color-outline-variant-light: #d0c5b4;
  --md-sys-color-scrim-light: #000000;
  /* dark */
  --md-sys-color-primary-dark: #f8bd2a;
  --md-sys-color-on-primary-dark: #402d00;
  --md-sys-color-primary-container-dark: #5c4300;
  --md-sys-color-on-primary-container-dark: #ffdfa0;
  --md-sys-color-secondary-dark: #9ecaff;
  --md-sys-color-on-secondary-dark: #003258;
  --md-sys-color-secondary-container-dark: #00497d;
  --md-sys-color-on-secondary-container-dark: #d1e4ff;
  --md-sys-color-tertiary-dark: #9ad67f;
  --md-sys-color-on-tertiary-dark: #0d3900;
  --md-sys-color-tertiary-container-dark: #1d5109;
  --md-sys-color-on-tertiary-container-dark: #b6f399;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #000000;
  --md-sys-color-on-background-dark: #e9e1d8;
  --md-sys-color-surface-dark: #1e1b16;
  --md-sys-color-on-surface-dark: #e9e1d8;
  --md-sys-color-surface-variant-dark: #4d4639;
  --md-sys-color-on-surface-variant-dark: #d0c5b4;
  --md-sys-color-outline-dark: #998f80;
  --md-sys-color-inverse-on-surface-dark: #1e1b16;
  --md-sys-color-inverse-surface-dark: #e9e1d8;
  --md-sys-color-inverse-primary-dark: #795900;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #f8bd2a;
  --md-sys-color-outline-variant-dark: #4d4639;
  --md-sys-color-scrim-dark: #000000;
  /* Custom Colors */
  --md-custom-color-Custom-Color-2: #9100f3;
  --md-custom-color-Custom-Color-2: #00f258;
  --md-custom-color-Custom-Color-3: #fbc02d;
  --md-custom-color-Custom-Color-4: #ce0013;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.5px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.4px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.1px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.5px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.5px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.1px;
}
